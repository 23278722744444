import React,{ Component } from 'react';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Contact from './Components/Contact'
import DetailProduct from './Components/ProductDetail';
import AllProducts from './Components/AllProduct';
import ProductDetail from './Components/ProductDetail';
import Subscription from "./Components/Subscription";
import RequestQuote from "./Components/RequestQuote";
import CategoryProduct from "./Components/CategoryProduct";
import About from './Components/About';
import NotFound from './Components/NotFound';
import helpers from "./Components/Globalsfunctions";
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShimmer: true,
      category_list: [],
      recentProductList: [],
      popularcategory: []
    };
  }

  getloader() {
    setTimeout(() => {
      this.setState({
        isShimmer: false
      });
    }, 1000);
  }

  componentDidMount() {
    this.getloader();
    helpers.getdataresponse("category-list-product-count").then(response => {
      if (response.data.status) {
        this.setState({ category_list: response.data.data });
      }
    });

    helpers.getdataresponse("recent-product").then(response => {
      if (response.data.status) {
        this.setState({ recentProductList: response.data.data });
      }
    });
  }
render() {
  return (
    <React.Fragment>

        <Router onUpdate={() => window.scrollTo(0, 0)}>
              <Header categorylist={this.state.category_list}/>
              <div>
                <Switch>
                <Route
                  path="/"
                  exact
                  strict
                  render={() => (
                    <Home
                      categorylist={this.state.category_list}
                      recentproductlist={this.state.recentProductList}
                      somepopularcategory={this.state.popularcategory}
                      isAuthed={true}
                    />
                  )}
                />
                <Route path="/contact" strict exact component={Contact} />
                <Route path="/about" strict exact component={About} />
                
                <Route
                  path="/subscribe"
                  strict
                  exact
                  component={Subscription}
                />
                <Route
                  path="/product-quotation"
                  strict
                  exact
                  render={() => (
                    <RequestQuote
                      categorylist={this.state.category_list}
                      isAuthed={true}
                    />
                  )}
                />

                <Route
                  path="/category-product/:someid"
                  exact
                  strict
                  render={() => (
                    <CategoryProduct
                      categorylist={this.state.category_list}
                      isAuthed={true}
                    />
                  )}
                />

                  <Route
                    path="/our-portfolio"
                    strict
                    exact
                    component={AllProducts}
                  />
                  <Route
                    path="/product-detail"
                    strict
                    exact
                    component={ProductDetail}
                  />
                  <Route  component={NotFound} />
                </Switch>

              </div>
              <Footer></Footer>
            </Router>  
    </React.Fragment>
 );
}
}

export default App;
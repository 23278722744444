import React, { Component } from "react";
import helpers from "./Globalsfunctions";

class RequestQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestqutname: "",
      requestqutemail: "",
      requestqutphone: "",
      requestqutcategory: "",
      requestqutwidth: "",
      requestqutheight: "",
      requestqutsizetype: "",
      requestqutStatus: "undefine",
      requestIsExist: "undefine"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleQuatationSubmit = e => {
    e.preventDefault();
    const requestquotationpostdata = {
      requestqutname: e.target.QutName.value,
      requestqutemail: e.target.QutEmail.value,
      requestqutphone: e.target.QutNumber.value,
      requestqutcategory: e.target.QutCategory.value,
      requestqutwidth: e.target.QutWidth.value,
      requestqutheight: e.target.QutHeight.value,
      requestqutsizetype: e.target.QutSizetype.value
    };

    helpers
      .postdataresponse("getQuotation", requestquotationpostdata)
      .then(res => {
        console.log(res);
        if (res.data.status === true) {
          if (res.data.isexist) {
            this.setState({
              requestIsExist: true
            });
          } else {
            this.setState({
              requestIsExist: false
            });
          }
        } else {
        }
        if (this.state.requestIsExist == false) {
          document.getElementById("requestQuoteForm").reset();
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <section className="login py-5 border-top-1 mt-6">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10 align-item-center">
                <div className="border">
                  <div className="bg-gray p-4">
                    <h3>Get Quotation For Our Product.</h3>
                    <p>We Send Our Pricing Quotation On Your E-Mail Address.</p>
                  </div>

                  <form
                    className="form-a contactForm"
                    onSubmit={this.handleQuatationSubmit}
                    id="requestQuoteForm"
                    role="form"
                  >
                    <fieldset className="p-4">
                      <div className="row">
                        <div className="col-sm-6  float-left">
                          <div className="form-group">
                            <input
                              type="text"
                              name="QutName"
                              required
                              pattern="[A-Za-z\s]+"
                              title="Please enter only Name. Number and spacial character are not allowed"
                              placeholder="Your Name"
                              className="form-control form-control-lg form-control-a"
                            />
                            <div className="validation"></div>
                          </div>

                          <div className="form-group">
                            <input
                              type="email"
                              required
                              name="QutEmail"
                              placeholder="Your E-Mail Address"
                              className="form-control form-control-lg form-control-a"
                            />
                            <div className="validation"></div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              name="QutNumber"
                              required
                              pattern="[0-9]{10}"
                              placeholder="Your Phone No."
                              className="form-control form-control-lg form-control-a"
                            />
                            <div className="validation"></div>
                          </div>
                        </div>

                        <div className="col-sm-6 float-left">
                          <div className="form-group">
                            <select
                              required
                              className="form-control form-control-lg form-control-a"
                              name="QutCategory"
                            >
                              <option value="">
                                Select Category Of Quotation
                              </option>
                              {this.props.categorylist.map(cat => {
                                return (
                                  <option key={cat.id} value={cat.cat_name}>
                                    {cat.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="validation"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <input
                              type="number"
                              name="QutWidth"
                              required
                              min="0"
                              placeholder="Enter Width"
                              className="form-control form-control-lg form-control-a"
                            />
                            <div className="validation"></div>
                          </div>
                        </div>
                        <div className="col-sm-1" align="center">
                          <p className="mulsize">*</p>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <input
                              name="QutHeight"
                              type="number"
                              required
                              min="0"
                              placeholder="Enter Height"
                              className="form-control form-control-lg form-control-a"
                            />
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="form-group">
                            <select
                              required
                              className="form-control form-control-lg form-control-a"
                              name="QutSizetype"
                            >
                              <option value="">Size Type</option>
                              <option value="Inches">Inches</option>
                              <option value="Feets">Feets</option>
                              <option value="Metres">Metres</option>
                            </select>
                            <div className="validation"></div>
                          </div>
                        </div>
                        <br />
                        
                          <div className="col-sm-12">
                            {this.state.requestIsExist == false ? (
                              <div
                                className="alert alert-success "
                                role="alert"
                              >
                                <strong>Success</strong> Thanks For Request Our
                                Team Will Contact You Soon.
                              </div>
                            ) : (
                              <div />
                            )}

                            {this.state.requestIsExist == true ? (
                              <div
                                className="alert alert-warning "
                                role="alert"
                              >
                                <strong>Warning</strong> You Have Already
                                Requested.
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                        <br />

                        <button type="submit" className="btn btn-a mt-3 ml-3">
                          Request Quotation
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default RequestQuote;

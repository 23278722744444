import React, { Component } from "react";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";


class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="click-closed"></div>
                <div className="box-collapse">
                    <div className="title-box-d">
                        <h3 className="title-d">Search Property</h3>
                    </div>
                    <span className="close-box-collapse right-boxed ion-ios-close"></span>
                    <div className="box-collapse-wrap form">
                        <form className="form-a">
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="Type">Keyword</label>
                                        <input type="text" className="form-control form-control-lg form-control-a" placeholder="Keyword" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="Type">Type</label>
                                        <select className="form-control form-control-lg form-control-a" id="Type">
                                            <option>All Type</option>
                                            <option>For Rent</option>
                                            <option>For Sale</option>
                                            <option>Open House</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <select className="form-control form-control-lg form-control-a" id="city">
                                            <option>All City</option>
                                            <option>Alabama</option>
                                            <option>Arizona</option>
                                            <option>California</option>
                                            <option>Colorado</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="bedrooms">Bedrooms</label>
                                        <select className="form-control form-control-lg form-control-a" id="bedrooms">
                                            <option>Any</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="garages">Garages</label>
                                        <select className="form-control form-control-lg form-control-a" id="garages">
                                            <option>Any</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="bathrooms">Bathrooms</label>
                                        <select className="form-control form-control-lg form-control-a" id="bathrooms">
                                            <option>Any</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="price">Min Price</label>
                                        <select className="form-control form-control-lg form-control-a" id="price">
                                            <option>Unlimite</option>
                                            <option>$50,000</option>
                                            <option>$100,000</option>
                                            <option>$150,000</option>
                                            <option>$200,000</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-b">Search Property</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
                    <div className="container">
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
                            aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <a className="navbar-brand text-brand" href="index.html"  style={{marginRight:"4rem"}}>
                            <img src="/assets/img/logo.png" width="175" />
                        </a>
                        {/* <button type="button" className="btn btn-link nav-search navbar-toggle-box-collapse d-md-none" data-toggle="collapse"
                    data-target="#navbarTogglerDemo01" aria-expanded="false">
                    <span className="fa fa-search" aria-hidden="true"></span>
                </button> */}
                        <div className="navbar-collapse collapse justify-content-center" id="navbarDefault">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link "
                                        to="/"
                                        exact
                                        activeClassName="active">
                                        Home
                        </NavLink>
                                </li>

                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Categories
                        </span>
                                    <div className="dropdown-menu mt-1" aria-labelledby="navbarDropdown">
                                        <div className="row categoryheader" >
                                            {this.props.categorylist.map(c => {
                                                return (

                                                    <div className="col-sm-4" key={c.id} >

                                                        <NavLink
                                                            className="nav-link "
                                                            to={"/category-product/" + c.id}
                                                            activeClassName="active"
                                                        >
                                                            <div style={{ float: "left" }}> &nbsp;&nbsp;
                                                <img
                                                                    src={
                                                                        "http://www.maauarts.com/apiadmin/assets/category/" +
                                                                        c.cat_image
                                                                    }
                                                                    className="categorynavimg"
                                                                    alt="Maa Urwashee Arts"
                                                                    width="30"
                                                                    style={{ borderRadius: "100%" }}
                                                                    height="30"
                                                                />
                                                            </div>
                                                            <div style={{ paddingBottom: "10px" }}>&nbsp;&nbsp;{c.cat_name}</div>
                                                        </NavLink>
                                                    </div>
                                                );
                                            })}

                                        </div>
                                    </div>

                                </li>

                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/our-portfolio"
                                        exact
                                        activeClassName="active">Our Portfolio</NavLink>
                                </li>



                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link "
                                        to="/about"
                                        exact
                                        activeClassName="active">
                                        About
                        </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link "
                                        to="/contact"
                                        exact
                                        activeClassName="active">Contact</NavLink>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        More
                        </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        <NavLink
                                            className="dropdown-item"
                                            to="/subscribe"
                                            exact
                                            style={{ minWidth: "220px" }}
                                            activeClassName="active">Subscription</NavLink>

                                        <NavLink
                                            className="dropdown-item"
                                            to="/product-quotation"
                                            exact
                                            style={{ minWidth: "220px" }}
                                            activeClassName="active">Product Quotation</NavLink>

                                    </div>
                                </li>

                            </ul>
                        </div>
                        {/* <button type="button" className="btn btn-b-n navbar-toggle-box-collapse d-none d-md-block" data-toggle="collapse"
                    data-target="#navbarTogglerDemo01" aria-expanded="false">
                    <span className="fa fa-search" aria-hidden="true"></span>
                </button> */}
                    </div>
                </nav>

            </React.Fragment>
        );
    }
}

export default Home;

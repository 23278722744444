import React, { Component } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import helpers from "./Globalsfunctions";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    if (
      this.props.location.productdetailProps === undefined ||
      this.props.location.productdetailProps === "undefined" ||
      this.props.location.productdetailProps === null ||
      this.props.location.productdetailProps === "undefined"
    ) {
      window.location.href = "/";
    } else {
      this.state = {
        productdatail: this.props.location.productdetailProps,
        requestIsExist: "undefined"
      };
    }
  }

  componentDidMount() {
    const { productdetailsdata } = this.props.location.productdetailProps;

    console.log(this.state);
    console.log(this.state.productdatail.productdetail.product_image1);

    window.scrollTo(0, 0);
  }

  handleSingleProductInquerySubmit = e => {
    e.preventDefault();
    const requestquotationpostdata = {
      ip_name: e.target.custname.value,
      ip_email: e.target.custemail.value,
      ip_phone: e.target.custphonenumber.value,
      ip_prodid: this.state.productdatail.productdetail.id,
      ip_prodcode: this.state.productdatail.productdetail.product_code
    };

    helpers
      .postdataresponse("getProductinquiry", requestquotationpostdata)
      .then(res => {
        console.log(res);
        if (res.data.status === true) {
          if (res.data.isexist) {
            this.setState({
              requestIsExist: true
            });
          } else {
            this.setState({
              requestIsExist: false
            });
          }
        } else {
        }
        if (this.state.requestIsExist == false) {
          document.getElementById("requestQuoteForm").reset();
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <section className="intro-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="title-single-box">
                  <h1 className="title-single">
                    {this.state.productdatail.productdetail.product_name}
                  </h1>
                  <span className="color-text-a">
                    {this.state.productdatail.productdetail.cat_name}
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  className="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="property-grid.html">Product Detail</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="property-single nav-arrow-b">
          <div className="container">
            <div className="row" align="center">
              <div className="col-sm-12">
                <div id="property-single-carousel">
                  <OwlCarousel
                    className="owl-carousel owl-arrow gallery-property"
                    loop
                    items={1}
                    margin={0}
                    width={500}
                    autoplay={true}
                    smartSpeed={500}
                    nav
                  >
                    <div className="carousel-item-b detailslider" >
                      <img
                        src={
                          "http://www.maauarts.com/apiadmin/assets/products/" +
                          this.state.productdatail.productdetail.product_image1
                        }
                        alt="Maa Urwashee Arts"
                        title={
                          this.state.productdatail.productdetail.product_name
                        }
                      />
                    </div>
                    <div className="carousel-item-b detailslider" >
                      <img
                        src={
                          "http://www.maauarts.com/apiadmin/assets/products/" +
                          this.state.productdatail.productdetail.product_image2
                        }
                        alt="Maa Urwashee Arts"
                        title={
                          this.state.productdatail.productdetail.product_name
                        }
                      />
                    </div>
                    <div className="carousel-item-b detailslider" >
                      <img
                        src={
                          "http://www.maauarts.com/apiadmin/assets/products/" +
                          this.state.productdatail.productdetail.product_image3
                        }
                        alt="Maa Urwashee Arts"
                        title={
                          this.state.productdatail.productdetail.product_name
                        }
                      />
                    </div>
                  </OwlCarousel>
                </div>
                <div className="row">
                  <div className="col-md-5 col-lg-5 section-md-t3">
                    <div className="property-summary">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="title-box-d">
                            <h3 className="title-d">Quick Summary</h3>
                          </div>
                        </div>
                      </div>
                      <div className="summary-list">
                        <ul className="list">
                          <li className="d-flex justify-content-between">
                            <strong>Property Code:</strong>
                            <span>
                              {
                                this.state.productdatail.productdetail
                                  .product_code
                              }
                            </span>
                          </li>
                          <li className="d-flex justify-content-between">
                            <strong>Product Price:</strong>
                            <span>For Price Contact 9772445552</span>
                          </li>
                          <li className="d-flex justify-content-between">
                            <strong>Product Size:</strong>
                            <span>
                              {
                                this.state.productdatail.productdetail
                                  .product_size
                              }
                            </span>
                          </li>
                          <li className="d-flex justify-content-between">
                            <strong>Product Category:</strong>
                            <span>
                              {this.state.productdatail.productdetail.cat_name}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between">
                            <strong>Product Artist:</strong>
                            <span>
                              {
                                this.state.productdatail.productdetail
                                  .product_artist
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 section-md-t3 mb-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="title-box-d">
                          <h3 className="title-d">Property Description</h3>
                        </div>
                      </div>
                    </div>
                    <div className="property-description">
                      <div className="col-md-12 col-lg-12">
                        <div className="property-contact">
                          <form
                            id="requestQuoteForm"
                            className="form-a"
                            onSubmit={this.handleSingleProductInquerySubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="productcode"
                                    readOnly="readonly"
                                    className="form-control form-control-lg form-control-a"
                                    id="inputName"
                                    value={
                                      this.state.productdatail.productdetail
                                        .product_code
                                    }
                                    placeholder="Product Code"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 mb-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="productname"
                                    readOnly="readonly"
                                    className="form-control form-control-lg form-control-a"
                                    id="inputName"
                                    value={
                                      this.state.productdatail.productdetail
                                        .product_name
                                    }
                                    placeholder="Product Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="custname"
                                    className="form-control form-control-lg form-control-a"
                                    id="inputName"
                                    placeholder="Your Name"
                                    required="required"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    required="required"
                                    name="custphonenumber"
                                    id="email"
                                    pattern="[0-9]{10}"
                                    className="form-control form-control-lg form-control-a"
                                    placeholder="Mobile Number"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="custemail"
                                    className="form-control form-control-lg form-control-a"
                                    id="inputName"
                                    placeholder="Your Email"
                                    required="required"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                {this.state.requestIsExist == false ? (
                                  <div
                                    className="alert alert-success "
                                    role="alert"
                                  >
                                    <strong>Success</strong> Thanks For Request
                                    Our Team Will Contact You Soon.
                                  </div>
                                ) : (
                                  <div />
                                )}

                                {this.state.requestIsExist == true ? (
                                  <div
                                    className="alert alert-warning "
                                    role="alert"
                                  >
                                    <strong>Warning</strong> You Have Already
                                    Requested.
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </div>

                              <div className="col-md-12">
                                <button type="submit" className="btn btn-a">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductDetail;

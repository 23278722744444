import React, { Component } from "react";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
            
        <footer>
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                <nav className="nav-footer">
                    <ul className="list-inline">
                    <li className="list-inline-item">
                        <NavLink to="/our-portfolio" exact>Portfolio</NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink to="/about" exact>About</NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink to="/contact" exact>Contact</NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink to="/subscribe" exact>Subscription</NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink to="product-quotation" exact>Product Quotation</NavLink>
                    </li>
                    </ul>
                </nav>
                <div className="socials-a">
                    <ul className="list-inline">
                    <li className="list-inline-item">
                        <a href="https://www.facebook.com/MaaUrwasheeArt" target="_blank">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://www.instagram.com/maa_urwashee_arts/"  target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts" target="_top">
                        <i className="fa fa-google" aria-hidden="true"></i>
                        </a>
                    </li>
                    
                    </ul>
                </div>
                <div className="copyright-footer">
                    <p className="copyright color-text-a">
                    &copy; Copyright
                    <span className="color-a">&nbsp;&nbsp;<b>Maa Urwashee Arts</b>&nbsp;&nbsp;</span> All Rights Reserved.
                    </p>
                </div>
                <div className="credits">
                    Designed & Developed By <a href="https://www.facebook.com/krishna.sharma.73"><b>Krishna</b></a>
                </div>
                </div>
            </div>
            </div>
        </footer>
        <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
          <div id="preloader"></div>  
  
      </React.Fragment>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import helpers from "./Globalsfunctions";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriberName: "",
      subscriberEmail: "",
      isexist: "undefine"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSubscribeSubmit = e => {
    e.preventDefault();
    console.log(this.state);

    const subscribepostdata = {
      subscriptionname: this.state.subscriberName,
      subscriptionemail: this.state.subscriberEmail
    };

    helpers
      .postdataresponse("subscriptionsubmit", subscribepostdata)
      .then(res => {
        console.log(res);
        if (res.data.status === true) {
          if (res.data.isexist) {
            this.setState({
              isexist: true
            });
          } else {
            this.setState({
              isexist: false
            });
            document.getElementById("subscribeform").reset();
          }
        } else {
        }
        
      });
  };

  handelSubscriberName = e => {
    this.setState({
      subscriberName: e.target.value
    });
  };

  handelSubscriberEmail = e => {
    this.setState({
      subscriberEmail: e.target.value
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="login py-5 border-top-1 mt-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-8 align-item-center">
                {this.state.isexist == false ? (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>Success</strong> Thanks For Subscribe
                  </div>
                ) : (
                  <div />
                )}

                {this.state.isexist == true ? (
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>Warning</strong> User Already Exist.
                  </div>
                ) : (
                  <div />
                )}

                <div className="border" align="center">
                  <h3 className="bg-gray p-4">
                    Subscription Now For New Product.
                  </h3>

                  <form className="form-a contactForm"  onSubmit={this.handleSubscribeSubmit}
                    id="subscribeform" role="form">
                <div id="sendmessage">Your message has been sent. Thank you!</div>
                <div id="errormessage"></div>
                <div className="row ml-3 mr-3">
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <input type="text" name="name" onChange={this.handelSubscriberName} className="form-control form-control-lg form-control-a" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
                      <div className="validation"></div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <input name="email" type="email"  onChange={this.handelSubscriberEmail}
                        className="form-control form-control-lg form-control-a" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email"/>
                      <div className="validation"></div>
                    </div>
                  </div>
                  
                  <div className="col-md-12" >
                    <button  type="submit" className="btn btn-a mb-3" > Subscribe  </button>
                  </div>
                </div>
              </form>



                 

                      
                    
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Subscription;

import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import Image from "react-shimmer";
import Spinner from "./shimmer/AboutShimmer";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <React.Fragment>
          <div className="intro intro-carousel" style={{marginTop:"95px"}}>
                <div id="carousel">

                <OwlCarousel
                    className="owl-carousel owl-theme"
                    loop
                    items={1}
                    nav 
                    margin={0}
                    lazyLoad={true}
                    autoplay={true}
                    animateOut= {'fadeOut'}
                    smartSpeed={500}
                    nav
                >
                    <div className="carousel-item-a intro-item bg-image" style={{ backgroundImage: "url('/assets/img/maaurwasheearts/maaurwasheearts1.jpg')", }}>
                        <div className="overlay overlay-a"></div>
                        <div className="intro-content display-table">
                        <div className="table-cell">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                <div className="intro-body">
                                    {/* <p className="intro-title-top">Doral, Florida
                                    <br/> 78345</p> */}
                                    <h1 className="intro-title mb-4">
                                    <span className="color-b" style={{fontSize:"20px"}}>The Memorable Arts</span> 
                                    <br/> Incredible Art Of Maa Urwashee Arts</h1>
                                    <p className="intro-subtitle intro-price">
                                    <NavLink to="/our-portfolio" exact><span className="price-a">Explore</span></NavLink>
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item-a intro-item bg-image" style={{ backgroundImage: "url('/assets/img/maaurwasheearts/maaurwasheearts2.jpg')"}}>
                        <div className="overlay overlay-a"></div>
                        <div className="intro-content display-table">
                        <div className="table-cell">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                <div className="intro-body">
                                    {/* <p className="intro-title-top">Doral, Florida
                                    <br/> 78345</p> */}
                                    <h1 className="intro-title mb-4">
                                    <span className="color-b" style={{fontSize:"20px"}}>Quality Work </span> 
                                    <br/> We believe and we because of quality work</h1>
                                    <p className="intro-subtitle intro-price">
                                    <NavLink to="/our-portfolio" exact><span className="price-a">Explore</span></NavLink>
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item-a intro-item bg-image" style={{ backgroundImage: "url('/assets/img/maaurwasheearts/maaurwasheearts3.jpg')"}}>

                        <div className="overlay overlay-a"></div>
                        <div className="intro-content display-table">
                        <div className="table-cell">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                <div className="intro-body">
                                    {/* <p className="intro-title-top">Doral, Florida
                                    <br/> 78345</p> */}
                                    <h1 className="intro-title mb-4">
                                    <span className="color-b" style={{fontSize:"20px"}}>Maa Urwashee Arts </span> 
                                    <br/> You Choose Anything In Art,  Maa Urwashee Arts Will Deliver</h1>
                                    <p className="intro-subtitle intro-price">
                                    <NavLink to="/our-portfolio" exact><span className="price-a">Explore</span></NavLink>
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </OwlCarousel>
                </div>
            </div>
           

          <section className="section-services section-t8">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                      <h2 className="title-a">Our Categories</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              {this.props.categorylist.map((c, index) => {
                    return (
                      
                      <div  key={c.id} className="col-md-4 mt-3">
                        <div className="card-box-c foo">
                          <div className="card-header-c d-flex">
                            <div className="card-box-ico">
                            <NavLink
                                className="nav-link "                                    
                                to={"/category-product/" + c.id}
                                activeClassName="active"
                            > 
                            <Image
                                    className="img-a img-fluid"
                                    src={
                                      "http://www.maauarts.com/apiadmin/assets/category/" +
                                      c.cat_image
                                    }
                                    alt="Maa Urwashee Arts" 
                                    title={c.cat_name}
                                    width={60}
                                    height={60}
                                  />
                                  </NavLink>
                            </div>
                            <div className="card-title-c align-self-center">
                              <h2 className="title-c">{c.cat_name}</h2>
                            </div>
                          </div>
                          <div className="card-body-c">
                          </div>
                          
                        </div>
                      </div>
                      );
                  })}
             </div>
            </div>
          </section>
          
          <section className="section-property section-t8">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                      <h2 className="title-a">Recent Products</h2>
                    </div>
                    <div className="title-link">
                    <NavLink
                          className="nav-link "
                          to="/our-portfolio"
                          exact>All Products
                        <span className="ion-ios-arrow-forward"></span>
                        </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div id="property-carousel">
                
              <OwlCarousel
                    className="owl-carousel owl-theme"
                    items={3}
                    margin={0}
                    lazyLoad={true}
                    autoplay={true}
                    animateOut= {'fadeOut'}
                    smartSpeed={500}
                    responsive= {{
                      0: {  
                        items: 1,
                      },
                      769: {
                        items: 2,
                      },
                      992: {
                        items: 3,
                      }
                    }}
                    
                > 
                {this.props.recentproductlist.map(rp => {
                  return (
                  
                <div className="carousel-item-b" key={rp.id}>
                {/* <div className="col-md-4"> */}
          <div className="card-box-a card-shadow">
            <div className="img-box-a">
                <Image
                  className="img-a img-fluid"
                  src={"http://www.maauarts.com/apiadmin/assets/products/" + rp.product_image1} 
                  alt="Maa Urwashee Arts" 
                  title={rp.product_name}
                  fallback={<Spinner />}
                  alt="Maa Urwashee Arts"
                />  
            </div>
            <div className="card-overlay">
              <div className="card-overlay-a-content">
                <div className="card-header-a">
                  <h2 className="card-title-a">
                  <Link
                        to={{
                          pathname: "/product-detail",
                          productdetailProps: {
                            productdetail: rp
                          }
                        }} className="link-a"
                      >{rp.product_name}
                    </Link>
                  </h2>
                </div>
                <div className="card-body-a">
                    <Link
                        to={{
                          pathname: "/product-detail",
                          productdetailProps: {
                            productdetail: rp
                          }
                        }} className="link-a"
                      >
                        <b className="moreinfo">More Detail</b>
                          <span className="ion-ios-arrow-forward"></span>
                  </Link>
                </div>
                <div className="card-footer-a">
                  <ul className="card-info d-flex justify-content-around">

                    <li>
                      <h4 className="card-info-title"><b>Category</b></h4>
                      <span>{rp.cat_name}</span>
                    </li>

                    <li>
                      <h4 className="card-info-title"><b>Product Code</b></h4>
                      <span>{rp.product_code}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
       
                 </div>
                 );
                })}

              </OwlCarousel>
              </div>
            </div>
          </section>
          
          {/* <section className="section-agents section-t8">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                      <h2 className="title-a">Best Agents</h2>
                    </div>
                    <div className="title-link">
                      <a href="agents-grid.html">All Agents
                        <span className="ion-ios-arrow-forward"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card-box-d">
                    <div className="card-img-d">
                      <img src="/assets/img/agent-4.jpg" alt="" className="img-d img-fluid"/>
                    </div>
                    <div className="card-overlay card-overlay-hover">
                      <div className="card-header-d">
                        <div className="card-title-d align-self-center">
                          <h3 className="title-d">
                            <a href="agent-single.html" className="link-two">Margaret Sotillo
                              <br/> Escala</a>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body-d">
                        <p className="content-d color-text-a">
                          Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                        </p>
                        <div className="info-agents color-a">
                          <p>
                            <strong>Phone: </strong> +54 356 945234</p>
                          <p>
                            <strong>Email: </strong> agents@example.com</p>
                        </div>
                      </div>
                      <div className="card-footer-d">
                        <div className="socials-footer d-flex justify-content-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-dribbble" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-d">
                    <div className="card-img-d">
                      <img src="/assets/img/agent-1.jpg" alt="" className="img-d img-fluid"/>
                    </div>
                    <div className="card-overlay card-overlay-hover">
                      <div className="card-header-d">
                        <div className="card-title-d align-self-center">
                          <h3 className="title-d">
                            <a href="agent-single.html" className="link-two">Stiven Spilver
                              <br/> Darw</a>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body-d">
                        <p className="content-d color-text-a">
                          Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                        </p>
                        <div className="info-agents color-a">
                          <p>
                            <strong>Phone: </strong> +54 356 945234</p>
                          <p>
                            <strong>Email: </strong> agents@example.com</p>
                        </div>
                      </div>
                      <div className="card-footer-d">
                        <div className="socials-footer d-flex justify-content-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-dribbble" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-d">
                    <div className="card-img-d">
                      <img src="/assets/img/agent-5.jpg" alt="" className="img-d img-fluid"/>
                    </div>
                    <div className="card-overlay card-overlay-hover">
                      <div className="card-header-d">
                        <div className="card-title-d align-self-center">
                          <h3 className="title-d">
                            <a href="agent-single.html" className="link-two">Emma Toledo
                              <br/> Cascada</a>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body-d">
                        <p className="content-d color-text-a">
                          Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                        </p>
                        <div className="info-agents color-a">
                          <p>
                            <strong>Phone: </strong> +54 356 945234</p>
                          <p>
                            <strong>Email: </strong> agents@example.com</p>
                        </div>
                      </div>
                      <div className="card-footer-d">
                        <div className="socials-footer d-flex justify-content-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="fa fa-dribbble" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <section className="section-news section-t8">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                      <h2 className="title-a">Latest News</h2>
                    </div>
                    <div className="title-link">
                      <a href="blog-grid.html">All News
                        <span className="ion-ios-arrow-forward"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="new-carousel" className="owl-carousel owl-theme">
                <div className="carousel-item-c">
                  <div className="card-box-b card-shadow news-box">
                    <div className="img-box-b">
                      <img src="img/post-2.jpg" alt="" className="img-b img-fluid"/>
                    </div>
                    <div className="card-overlay">
                      <div className="card-header-b">
                        <div className="card-category-b">
                          <a href="#" className="category-b">House</a>
                        </div>
                        <div className="card-title-b">
                          <h2 className="title-2">
                            <a href="blog-single.html">House is comming
                              <br/> new</a>
                          </h2>
                        </div>
                        <div className="card-date">
                          <span className="date-b">18 Sep. 2017</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item-c">
                  <div className="card-box-b card-shadow news-box">
                    <div className="img-box-b">
                      <img src="img/post-5.jpg" alt="" className="img-b img-fluid"/>
                    </div>
                    <div className="card-overlay">
                      <div className="card-header-b">
                        <div className="card-category-b">
                          <a href="#" className="category-b">Travel</a>
                        </div>
                        <div className="card-title-b">
                          <h2 className="title-2">
                            <a href="blog-single.html">Travel is comming
                              <br/> new</a>
                          </h2>
                        </div>
                        <div className="card-date">
                          <span className="date-b">18 Sep. 2017</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item-c">
                  <div className="card-box-b card-shadow news-box">
                    <div className="img-box-b">
                      <img src="img/post-7.jpg" alt="" className="img-b img-fluid"/>
                    </div>
                    <div className="card-overlay">
                      <div className="card-header-b">
                        <div className="card-category-b">
                          <a href="#" className="category-b">Park</a>
                        </div>
                        <div className="card-title-b">
                          <h2 className="title-2">
                            <a href="blog-single.html">Park is comming
                              <br/> new</a>
                          </h2>
                        </div>
                        <div className="card-date">
                          <span className="date-b">18 Sep. 2017</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item-c">
                  <div className="card-box-b card-shadow news-box">
                    <div className="img-box-b">
                      <img src="img/post-3.jpg" alt="" className="img-b img-fluid"/>
                    </div>
                    <div className="card-overlay">
                      <div className="card-header-b">
                        <div className="card-category-b">
                          <a href="#" className="category-b">Travel</a>
                        </div>
                        <div className="card-title-b">
                          <h2 className="title-2">
                            <a href="#">Travel is comming
                              <br/> new</a>
                          </h2>
                        </div>
                        <div className="card-date">
                          <span className="date-b">18 Sep. 2017</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-testimonials section-t8 nav-arrow-a">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                      <h2 className="title-a">Testimonials</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div id="testimonial-carousel" className="owl-carousel owl-arrow">
                <div className="carousel-item-a">
                  <div className="testimonials-box">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="testimonial-img">
                          <img src="img/testimonial-1.jpg" alt="" className="img-fluid"/>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="testimonial-ico">
                          <span className="ion-ios-quote"></span>
                        </div>
                        <div className="testimonials-content">
                          <p className="testimonial-text">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis, cupiditate ea nam praesentium
                            debitis hic ber quibusdam
                            voluptatibus officia expedita corpori.
                          </p>
                        </div>
                        <div className="testimonial-author-box">
                          <img src="img/mini-testimonial-1.jpg" alt="" className="testimonial-avatar"/>
                          <h5 className="testimonial-author">Albert & Erika</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item-a">
                  <div className="testimonials-box">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="testimonial-img">
                          <img src="img/testimonial-2.jpg" alt="" className="img-fluid"/>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="testimonial-ico">
                          <span className="ion-ios-quote"></span>
                        </div>
                        <div className="testimonials-content">
                          <p className="testimonial-text">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis, cupiditate ea nam praesentium
                            debitis hic ber quibusdam
                            voluptatibus officia expedita corpori.
                          </p>
                        </div>
                        <div className="testimonial-author-box">
                          <img src="img/mini-testimonial-2.jpg" alt="" className="testimonial-avatar"/>
                          <h5 className="testimonial-author">Pablo & Emma</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
           */}
          <section className="section-footer">
              <div className="container"></div>
          </section>
          
      </React.Fragment>
    );
  }
}

export default Home;

import React, { Component } from "react";
import helpers from "./Globalsfunctions";
import Image from "react-shimmer";
import { Link } from "react-router-dom";
import Spinner from "./shimmer/AboutShimmer";

class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islist: false,
      isgrid: true,
      allproduct_list: [],
      imagebeforedata: "/assets/img/loading.png"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ allproduct_list: [] });
    helpers.getdataresponse("product-list").then(response => {
      if (response.data.status) {
        if(response.data.data.length>0){
          this.setState({ 
            allproduct_list: response.data.data ,
            imagebeforedata:"/assets/img/loading.png",
          });
        }else{
          this.setState({ 
            allproduct_list: response.data.data ,
            imagebeforedata:"/assets/img/notfound.png",
          });
        }
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="intro-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="title-single-box">
                  <h1 className="title-single">Our All Products Listing</h1>
                  {/* <span className="color-text-a">Grid Properties</span> */}
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  className="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Our Products
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="property-grid grid">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="grid-option">
                  {/* <form>
              <select className="custom-select">
                <option selected>All</option>
                <option value="1">New to Old</option>
                <option value="2">For Rent</option>
                <option value="3">For Sale</option>
              </select>
            </form> */}
                </div>
              </div>

              {this.state.allproduct_list.length > 0 ? (
                this.state.allproduct_list.map(p => {
                  return (
                    <div className="col-md-4" key={p.id}>
                      <div className="card-box-a card-shadow">
                        <div className="img-box-a">
                          <Image
                            className="img-a img-fluid"
                            src={
                              "http://www.maauarts.com/apiadmin/assets/products/" +
                              p.product_image1
                            }
                            alt="Maa Urwashee Arts"
                            title={p.product_name}
                            fallback={<Spinner />}
                            alt="Maa Urwashee Arts"
                          />
                        </div>
                        <div className="card-overlay">
                          <div className="card-overlay-a-content">
                            <div className="card-header-a">
                              <h2 className="card-title-a">
                                <a href="#">{p.product_name}</a>
                              </h2>
                            </div>
                            <div className="card-body-a">
                              <Link
                                to={{
                                  pathname: "/product-detail",
                                  productdetailProps: {
                                    productdetail: p
                                  }
                                }}
                                className="link-a"
                              >
                                <b className="moreinfo">More Detail</b>
                                <span className="ion-ios-arrow-forward"></span>
                              </Link>
                            </div>
                            <div className="card-footer-a">
                              <ul className="card-info d-flex justify-content-around">
                                <li>
                                  <h4 className="card-info-title">
                                    <b>Category</b>
                                  </h4>
                                  <span>{p.cat_name}</span>
                                </li>

                                <li>
                                  <h4 className="card-info-title">
                                    <b>Product Code</b>
                                  </h4>
                                  <span>{p.product_code}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="container text-center">
                  <div className="col-sm-12">
                    <img src={this.state.imagebeforedata} alt="" />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="row">
        <div className="col-sm-12">
          <nav className="pagination-a">
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link" href="#" tabindex="-1">
                  <span className="ion-ios-arrow-back"></span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">1</a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">2</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">3</a>
              </li>
              <li className="page-item next">
                <a className="page-link" href="#">
                  <span className="ion-ios-arrow-forward"></span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
     */}
          </div>
        </section>

        <section className="section-footer">
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AllProducts;

import axios from "axios";
// let Baseurl = "http://maauarts.com/apis/";
let Baseurl = "http://apiadmin.maauarts.com/apis/";
const helpers = {
 
  getdataresponse: function(apiname) {
    var headers = {
      "Content-Type": "application/json",
      "x-api-key" : "KrIsHnA1231231234@MaAUaRtS"
     };

    //get response using promise
    return new Promise((resolve, reject) => {
      axios
        .get(Baseurl + apiname, { headers: headers })
        .then(function(response) {
          if (response.status) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  postdataresponse: function(apiname, data) {
    var headers = {
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      "x-api-key" : "KrIsHnA1231231234@MaAUaRtS"
        
    };
    
    return new Promise((resolve, reject) => {
      axios
        .post(Baseurl + apiname, data)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }
};
export default helpers;

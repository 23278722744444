import React, { Component } from "react";
class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>

        <section className="intro-single">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-8">
          <div className="title-single-box">
            <h1 className="title-single">Maa Urwashee Arts</h1>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <nav aria-label="breadcrumb" className="breadcrumb-box d-flex justify-content-lg-end">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  
  <section className="section-about">
    <div className="container">
      <div className="row">
        <div className="col-md-12 section-t5">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <img src="/assets/img/AboutMaaUrwasheeArts.jpg" alt="" className="img-fluid"/>
            </div>
            <div className="col-lg-2  d-none d-lg-block">
              <div className="title-vertical d-flex justify-content-start">
              </div>
            </div>
            <div className="col-md-6 col-lg-5 section-md-t3">
              <div className="title-box-d">
                <h3 className="title-d">About
                  <span className="color-d">&nbsp;&nbsp;Maa Urwashee Arts</span></h3>
              </div>
              <p className="color-text-a">
                    Maa Urwarshee Arts is a art farm here we make all type of
                    paintings and different type of art like Abstract
                    Art,Surrealism Arts, Conceptual Art, Photorealism Arts,
                    Futurism Arts, Impressionism Arts, Craft Arts, Portrait
                    Sketching, Morden Arts, All Type Designing, Indian Arts,
                    Pichhwai Arts, RC Arts, God Painting, TA Chart & Model,
                    Murti (Carving) Arts, Human Portrait.
              </p>
              <p className="color-text-a">
                    We also send our
                    painting inside india or out of india(International Market).
                    We Also Make Art that are trends and nathdwara arts and any
                    type of art.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
   <section className="section-agents section-t8">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="title-wrap d-flex justify-content-between">
            <div className="title-box">
              <h2 className="title-a">Meet Our Team</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card-box-d">
            <div className="card-img-d">
              <img src="/assets/img/user-male.png" alt="" className="img-d img-fluid"/>
            </div>
            <div className="card-overlay card-overlay-hover">
              <div className="card-header-d">
                <div className="card-title-d align-self-center">
                  <h3 className="title-d">
                    <a href="agent-single.html" className="link-two">
                      <br/> Yashwant Jangid</a>
                  </h3>
                </div>
              </div>
              <div className="card-body-d">
                <p className="content-d color-text-a">
                  Founder/Artist
                </p>
                <div className="info-agents color-a">
                  <p>
                    <strong>Phone: </strong> +91 9828352357</p>
                  <p>
                    <strong>Email: </strong> maaurwasheeart@gmail.com</p>
                </div>
              </div>
              <div className="card-footer-d">
                <div className="socials-footer d-flex justify-content-center">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/MaaUrwasheeArt" className="link-one" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/maa_urwashee_arts/" className="link-one" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts" className="link-one" target="_blank"><i className="fa fa-google" aria-hidden="true"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card-box-d">
            <div className="card-img-d">
              <img src="/assets/img/user-male.png" alt="" className="img-d img-fluid"/>
            </div>
            <div className="card-overlay card-overlay-hover">
              <div className="card-header-d">
                <div className="card-title-d align-self-center">
                  <h3 className="title-d">
                    <a href="agent-single.html" className="link-two">
                      <br/> Bhupesh Jangid</a>
                  </h3>
                </div>
              </div>
              <div className="card-body-d">
                <p className="content-d color-text-a">
                  MD/Artist
                </p>
                <div className="info-agents color-a">
                  <p>
                    <strong>Phone: </strong> +91 9772445552</p>
                  <p>
                    <strong>Email: </strong> jangidbhupesh1989@gmail.com</p>
                </div>
              </div>
              <div className="card-footer-d">
                <div className="socials-footer d-flex justify-content-center">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/MaaUrwasheeArt" className="link-one" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/maa_urwashee_arts/" className="link-one" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts" className="link-one" target="_blank"><i className="fa fa-google" aria-hidden="true"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card-box-d">
            <div className="card-img-d">
              <img src="/assets/img/user-female.png" alt="" className="img-d img-fluid"/>
            </div>
            <div className="card-overlay card-overlay-hover">
              <div className="card-header-d">
                <div className="card-title-d align-self-center">
                  <h3 className="title-d">
                    <a href="agent-single.html" className="link-two">
                      <br/> Geetika Jangid</a>
                  </h3>
                </div>
              </div>
              <div className="card-body-d">
                <p className="content-d color-text-a">
                  Teacher/Artist
                </p>
                <div className="info-agents color-a">
                  
                </div>
              </div>
              <div className="card-footer-d">
                <div className="socials-footer d-flex justify-content-center">
                <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/MaaUrwasheeArt" className="link-one" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/maa_urwashee_arts/" className="link-one" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts" className="link-one" target="_blank"><i className="fa fa-google" aria-hidden="true"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
       

        <div className="col-md-3">
          <div className="card-box-d">
            <div className="card-img-d">
              <img src="/assets/img/user-female.png" alt="" className="img-d img-fluid"/>
            </div>
            <div className="card-overlay card-overlay-hover">
              <div className="card-header-d">
                <div className="card-title-d align-self-center">
                  <h3 className="title-d">
                    <a href="agent-single.html" className="link-two">
                      <br/> Rajeshwari Jangid</a>
                  </h3>
                </div>
              </div>
              <div className="card-body-d">
                <p className="content-d color-text-a">
                  Teacher/Artist
                </p>
                <div className="info-agents color-a">
                </div>
              </div>
              <div className="card-footer-d">
                <div className="socials-footer d-flex justify-content-center">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/MaaUrwasheeArt" className="link-one" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/maa_urwashee_arts/" className="link-one" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts" className="link-one" target="_blank"><i className="fa fa-google" aria-hidden="true"></i></a>
                    </li>
                  </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
       
       
      </div>
    </div>
  </section>
 
 <section className="section-footer">
    <div className="container">
      <div className="row">
      </div>
    </div>
  </section>  
      </React.Fragment>
    );
  }
}

export default About;

import React, { Component } from "react";
import helpers from "./Globalsfunctions";
import { FacebookProvider, Page, Share, Like } from "react-facebook";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsubmitstate: "",
      contactname: "",
      contactemail: "",
      contactmobile: "",
      contactmessage: "",
      isexist: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleContactSubmit = e => {
    e.preventDefault();

    const contactpostdata = {
      contactname: this.state.contactname,
      contactemail: this.state.contactemail,
      contactphone: this.state.contactmobile,
      contactmsz: this.state.contactmessage
    };

    // const fd = new FormData();
    // fd.append("contactname", this.state.contactname);
    // fd.append("contactemail", this.state.contactemail);
    // fd.append("contactphone", this.state.contactmobile);
    // fd.append("contactmsz", this.state.contactmessage);
    helpers.postdataresponse("contactsubmit", contactpostdata).then(res => {
      if(res.data.isexist){
        this.setState({
          contactsubmitstate: true,
          isexist:true
        });
      }else{
        this.setState({
          contactsubmitstate: true,
          isexist:false
        });
        document.mycontactform.reset();  
      }

       
     
      console.log(this.state.isexist,"here is ");
    });
  };

  handelContactname = e => {
    this.setState({ contactname: e.target.value });
  };

  handelContactemail = e => {
    this.setState({ contactemail: e.target.value });
  };

  handelContactmobile = e => {
    this.setState({ contactmobile: e.target.value });
  };

  handelContactmessage = e => {
    this.setState({ contactmessage: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <section className="intro-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="title-single-box">
                  <h1 className="title-single">Contact US</h1>
                  <span className="color-text-a"></span>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  className="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact-map box">
                  <div id="map" className="contact-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.9680892080455!2d73.816945950044!3d24.93315698394132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39680d4017f15681%3A0x8d5b5ddf003fd774!2sMaa%20Urwashee%20Arts!5e0!3m2!1sen!2sin!4v1570504099684!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 section-t8">
                <div className="row">
                  <div className="col-md-7">
                    <form
                      className="form-a contactForm"
                      method="post"
                      name="mycontactform"
                      onSubmit={this.handleContactSubmit}
                      role="form"
                    >
                      
                      <div id="errormessage"></div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              onChange={this.handelContactname}
                              name="name"
                              className="form-control form-control-lg form-control-a"
                              placeholder="Your Name"
                              data-rule="minlen:4"
                              data-msg="Please enter at least 4 chars"
                            />
                            <div className="validation"></div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <input
                              name="email"
                              type="email"
                              onChange={this.handelContactemail}
                              className="form-control form-control-lg form-control-a"
                              placeholder="Your Email"
                              data-rule="email"
                              data-msg="Please enter a valid email"
                            />
                            <div className="validation"></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="mobile"
                              onChange={this.handelContactmobile}
                              className="form-control form-control-lg form-control-a"
                              placeholder="Mobile"
                              data-rule="minlen:4"
                              data-msg="Please enter at least 8 chars of subject"
                            />
                            <div className="validation"></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <textarea
                              name="message"
                              onChange={this.handelContactmessage}
                              className="form-control"
                              name="message"
                              cols="45"
                              rows="8"
                              data-rule="required"
                              data-msg="Please write something for us"
                              placeholder="Message"
                            ></textarea>
                            <div className="validation"></div>
                          </div>
                        </div>

                        <br />
                        {this.state.isexist===false ? (
                          <div
                            className="alert alert-success fade show"
                            role="alert"
                          >
                            <strong>Success</strong> Thanks For Contact Us.
                          </div>
                        ) : (
                          <div />
                        )}

                        {this.state.isexist===true ? (
                          <div
                            className="alert alert-warning  fade show"
                            role="alert"
                          >
                            <strong>Already Exist</strong> You have already contact with us we will contact you soon.<br/> Thank You
                          </div>
                        ) : (
                          <div />
                        )}

                        <div className="col-md-12">
                          <button type="submit" className="btn btn-a">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-5 section-md-t3">
                    <div className="icon-box section-b2">
                      <div className="icon-box-icon">
                        <span className="ion-ios-paper-plane"></span>
                      </div>
                      <div className="icon-box-content table-cell">
                        <div className="icon-box-title">
                          <h4 className="icon-title">Say Hello</h4>
                        </div>
                        <div className="icon-box-content">
                          <p className="mb-1">
                            Email.
                            <span className="color-a">
                              {" "}
                              maaurwasheeart@gmail.com
                            </span>
                          </p>
                          <p className="mb-1">
                            Email.
                            <span className="color-a">
                              {" "}
                              jangidbhupesh1989@gmail.com
                            </span>
                          </p>
                          <p className="mb-1">
                            Phone.
                            <span className="color-a">+91 9828 352 357,</span>
                            &nbsp;&nbsp;
                            <span className="color-a">+91 9772 445 552</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="icon-box section-b2">
                      <div className="icon-box-icon">
                        <span className="ion-ios-pin"></span>
                      </div>
                      <div className="icon-box-content table-cell">
                        <div className="icon-box-title">
                          <h4 className="icon-title">Find us in</h4>
                        </div>
                        <div className="icon-box-content">
                          <p className="mb-1">
                            Ramdwara near government higher secondary school,
                            fouz mohalla, Nathdwara (313301)
                            <br /> Rajasthan, India.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="icon-box">
                      <div className="icon-box-icon">
                        <span className="ion-ios-redo"></span>
                      </div>
                      <div className="icon-box-content table-cell">
                        <div className="icon-box-title">
                          <h4 className="icon-title">Social networks</h4>
                        </div>
                        <div className="icon-box-content">
                          <div className="socials-footer">
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  href="https://www.facebook.com/MaaUrwasheeArt"
                                  target="_blank"
                                >
                                  <i
                                    className="fa fa-facebook"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a href="#">
                                  <i
                                    className="fa fa-twitter"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="https://www.instagram.com/maa_urwashee_arts/"
                                  target="_blank"
                                >
                                  <i
                                    className="fa fa-instagram"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href="mailto:jangidbhupesh1989@gmail.com?Subject=Maa Urwashee Arts"
                                  target="_top"
                                >
                                  <i
                                    className="fa fa-google"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            </ul>
                            <br />
                            <FacebookProvider appId="888256954580074">
                              <Like
                                href="https://www.facebook.com/MaaUrwasheeArt/"
                                colorScheme="dark"
                                showFaces
                                share
                              />
                            </FacebookProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-agents section-t8">
          <div className="container">
            <div className="row">
              <div className="col-md-6" align="center">
                <FacebookProvider appId="888256954580074">
                  <Page
                    href="https://www.facebook.com/MaaUrwasheeArt/"
                    tabs="timeline"
                    width="500"
                  />
                </FacebookProvider>
              </div>

              <div className="col-md-6">
                <FacebookProvider appId="888256954580074">
                  <Page
                    href="https://www.facebook.com/MaaUrwasheeArt/"
                    tabs="messages"
                    width="500"
                  />
                </FacebookProvider>
              </div>
            </div>
          </div>
        </section>

        <section className="section-footer">
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
class AboutShimmer extends Component {
  render() {
    return (
      <Shimmer>
        <div className="shimmerheader container" style={{ height: "300px" }} />
      </Shimmer>
    );
  }
}

export default AboutShimmer;

import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
            <section className="intro-single">
              <div className="container">
                <div className="row">
                  <div className="col-md-12" align="center">
                      <img src="/assets/img/pagenotfound.png" width="300" height="300"/>
                  </div>
                </div>
              </div>
            </section>
      </React.Fragment>
    );
    
  }
}

export default NotFound;
